exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-data-access-tsx": () => import("./../../../src/pages/about/data-access.tsx" /* webpackChunkName: "component---src-pages-about-data-access-tsx" */),
  "component---src-pages-about-data-limitations-tsx": () => import("./../../../src/pages/about/data-limitations.tsx" /* webpackChunkName: "component---src-pages-about-data-limitations-tsx" */),
  "component---src-pages-about-data-sources-tsx": () => import("./../../../src/pages/about/data-sources.tsx" /* webpackChunkName: "component---src-pages-about-data-sources-tsx" */),
  "component---src-pages-about-methods-tsx": () => import("./../../../src/pages/about/methods.tsx" /* webpackChunkName: "component---src-pages-about-methods-tsx" */),
  "component---src-pages-about-overview-tsx": () => import("./../../../src/pages/about/overview.tsx" /* webpackChunkName: "component---src-pages-about-overview-tsx" */),
  "component---src-pages-about-publications-tsx": () => import("./../../../src/pages/about/publications.tsx" /* webpackChunkName: "component---src-pages-about-publications-tsx" */),
  "component---src-pages-global-tsx": () => import("./../../../src/pages/global.tsx" /* webpackChunkName: "component---src-pages-global-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-map-tsx": () => import("./../../../src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */),
  "component---src-templates-pheic-tsx": () => import("./../../../src/templates/pheic.tsx" /* webpackChunkName: "component---src-templates-pheic-tsx" */),
  "component---src-templates-stakeholder-tsx": () => import("./../../../src/templates/stakeholder.tsx" /* webpackChunkName: "component---src-templates-stakeholder-tsx" */)
}

